import Helmet from "react-helmet";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { withPrefix, Link } from "gatsby";
import ThemeChanger from "../components/themeChanger";

const Navigation = ({ siteTitle }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <nav className="navbar">
      <div className="site-title">
        <Link to="/">{siteTitle}</Link>
      </div>
      <ul className={navbarOpen ? "nav-menu active" : "nav-menu"}>
        <li className="nav-item">
          <Link to="/" className="nav-link">
            home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-link">
            about
          </Link>
        </li>
      </ul>
      <div
        className={navbarOpen ? "hamburger active" : "hamburger"}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <ThemeChanger />
    </nav>
  );
};

Navigation.propTypes = {
  siteTitle: PropTypes.string,
};

Navigation.defaultProps = {
  siteTitle: ``,
};

export default Navigation;
